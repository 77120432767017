#WACContainer.WACContainer {
  @import 'node_modules/swiper/swiper';
}

#WACContainer.WACContainer .WACHeader__Name {
  color: white;
}

#WACContainer.WACContainer .cds--chat-btn.cds--btn--sm, #WACContainer.WACContainer .ibm-web-chat--default-styles button:not([class*=cds--]) {
  width: 60%;
}

#WACContainer.WACContainer .WAC__sent--text>span {
  color: white;
}

#WACContainer.WACContainer .WACHomeScreen.WACHomeScreen--hydrationComplete button.WACHomeScreen__starter {
  border-color: red;
  text-align: center;
}

#WACContainer.WACContainer .swiper {
  width: 100%;
}

#WACContainer.WACContainer .swiper-slide {
  padding-top: 3px;
  padding-bottom: 10px;
}

#WACContainer.WACContainer .swiper-slide,
#WACContainer.WACContainer .swiper-slide > * {
  max-width: 240px;
}

#WACContainer.WACContainer .cds--tile.Carousel__Card {
  border-radius: 0px;
  overflow: hidden;
  padding: 0;
  height: 100%;
  box-shadow: 0 1px 4px 0 #c5c2c2;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#WACContainer.WACContainer .Carousel__CardImage {
  display: block;
  width: 100%;
}

#WACContainer.WACContainer .Carousel__CardText {
  padding: 16px;
  flex-grow: 0; /* Allow the text to grow and take up available space */
}

#WACContainer.WACContainer .Carousel__CardTitle {
  color: red;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
  text-align: center;
}

#WACContainer.WACContainer .Carousel__CardOriginalPrice {
  color: #4d5358;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-decoration: line-through;
  text-align: center;
  margin-bottom: 0;
}

#WACContainer.WACContainer .Carousel__CardCurrentPrice {
  color: black;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

#WACContainer.WACContainer .Carousel__CardSubtitle {
  color: black;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
  margin-bottom: 8px;
  padding: 5px;
}

#WACContainer.WACContainer .Carousel__CardBenefits ul {
  color: black;
  list-style-type: disc; /* Customize bullet points */
  padding-left: 20px;    /* Adjust padding */
  padding-bottom: 20px;
  font-size: 12px;
}

#WACContainer.WACContainer .Carousel__CardBenefits li {
  margin-bottom: 5px;    /* Space between items */
}

#WACContainer.WACContainer .Carousel__Card .cds--btn.Carousel__CardButton {
  width: 60%;
  height: 32px;
  background-color: red;
  padding: 10px 20px;
  //margin: 0 auto 10px; /* Center the button and add margin at the bottom */
  margin: 0 10px 10px auto; /* Align the button to the right and add margin at the bottom */
  // display: block; /* Ensure the button is displayed as a block element */

}

#WACContainer.WACContainer .Carousel__Card .cds--btn.Carousel__CardButtonMessage {
  width: 60%;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

#WACContainer.WACContainer .Carousel__Card .cds--btn.Carousel__CardButton:last-child {
  border-radius: 8px;
}

#WACContainer.WACContainer .Carousel__Card .Carousel__CardButton,
#WACContainer.WACContainer .Carousel__Card .Carousel__CardButton:hover {
  text-decoration: none;
}

#WACContainer.WACContainer button.Carousel__NavigationButton,
#WACContainer.WACContainer .Carousel__BulletContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

#WACContainer.WACContainer .Carousel__Navigation .cds--btn,
#WACContainer.WACContainer .swiper .cds--btn {
  min-height: unset;
  min-width: unset;
  padding: unset;
}

#WACContainer.WACContainer .Carousel__Navigation {
  display: flex;
  width: fit-content;
  margin: 12px auto auto;
}

#WACContainer.WACContainer button.Carousel__NavigationButton {
  height: 32px;
  width: 32px;
  padding: 0;
}

#WACContainer.WACContainer button.Carousel__NavigationButton > svg {
  user-select: none;
  pointer-events: none;
  fill: #161616;
}

#WACContainer.WACContainer .Carousel__BulletContainer {
  padding-right: 8px;
  padding-left: 8px;
}

#WACContainer.WACContainer .cds--btn.Carousel__Bullet {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 4px;
  background-color: #c6c6c6;
  padding: 0;
  outline: none;
}

#WACContainer.WACContainer .cds--btn.Carousel__Bullet:focus {
  box-shadow: none;
}

#WACContainer.WACContainer .cds--btn.Carousel__Bullet--selected {
  background-color: #393939;
}

//cards
#WACContainer.WACContainer .Carousel__CardRow {
  display: flex;
  justify-content: space-between;
}

#WACContainer.WACContainer .Carousel__CardLabel {
  display: flex;
  color: #333;
  font-size: 12px;
  flex-shrink: 0;               /* Prevent the label from shrinking */
  white-space: nowrap;          /* Prevent the label from wrapping */
  margin-right: 8px;            /* Add some space between label and value */
  min-width: 90px; 
}

#WACContainer.WACContainer .Carousel__CardValue {
  font-weight: bold;
  font-size: 12px;
  color: #555;
  text-align: right;
  flex-grow: 1;                 /* Allow the value to grow and wrap */
  word-break: break-word;       /* Enable word wrapping if needed */
}

//bubbles
#WACContainer.WACContainer .BubbleCarousel .bubble-container {
  display: flex;
  overflow-x: auto;                   /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory;      /* Snap scrolling for smooth navigation */
  padding: 10px;
  -ms-overflow-style: none;           /* Hide scrollbar in Internet Explorer and Edge */
  scrollbar-width: none;              /* Hide scrollbar in Firefox */
}

#WACContainer.WACContainer .BubbleCarousel::-webkit-scrollbar {
  display: none;                      /* Hide scrollbar in Chrome, Safari, and Opera */
}

#WACContainer.WACContainer .BubbleCarousel .bubble-wrapper {
  display: flex;
  gap: 15px;                      /* Space between each bubble */
}

#WACContainer.WACContainer .BubbleCarousel .bubble-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;                 /* Prevents the bubble from shrinking */
  scroll-snap-align: start;       /* Snap alignment for each bubble */
  text-align: center;             /* Center-align label */
  cursor: pointer;                /* Show pointer cursor on hover */
}

#WACContainer.WACContainer .BubbleCarousel .Bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;                    /* Bubble size */
  height: 50px;
  background-color: #f0f0f0;      /* Light background color for bubbles */
  border-radius: 50%;             /* Make bubbles circular */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;               /* Hide any overflow to ensure circular shape */
}

#WACContainer.WACContainer .BubbleCarousel .Bubble__Icon {
  width: 100%;                    /* Make the image fill the bubble */
  height: 100%;
  object-fit: cover;              /* Ensure the image covers the entire bubble area */
  border-radius: 50%;             /* Keep the image circular within the bubble */
}

#WACContainer.WACContainer .Bubble__Label {
  margin-top: 4px;                /* Add some space between bubble and label */
  color: #333;
  font-size: 12px;
  text-align: center;
  max-width: 80px;                 /* Set a max width matching the bubble width */
  overflow-wrap: break-word;       /* Enable line breaks for long text */
  word-wrap: break-word;
  white-space: normal;             /* Allow label to wrap onto the next line */
}

//clickable tile
#WACContainer.WACContainer .ClickableTileCarousel .tile-container {
  display: flex;
  overflow-x: auto;                   /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory;      /* Snap scrolling for smooth navigation */
  padding: 10px;
  -ms-overflow-style: none;           /* Hide scrollbar in Internet Explorer and Edge */
  scrollbar-width: none;              /* Hide scrollbar in Firefox */
}

#WACContainer.WACContainer .ClickableTileCarousel::-webkit-scrollbar {
  display: none;                      /* Hide scrollbar in Chrome, Safari, and Opera */
}

#WACContainer.WACContainer .ClickableTileCarousel .tile-wrapper {
  display: flex;
  gap: 15px;                          /* Space between each tile */
}

#WACContainer.WACContainer .ClickableTileCarousel .tile-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;                     /* Prevent the tile from shrinking */
  scroll-snap-align: start;           /* Snap alignment for each tile */
  text-align: center;                 /* Center-align label */
  cursor: pointer;                    /* Show pointer cursor on hover */
}

#WACContainer.WACContainer .ClickableTileCarousel .Carousel__Tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 100px;                       /* Adjusted size to match the image */
  padding: 20px;                      /* Add padding inside the tile */
  background-color: #ffffff;          /* Light background color for tiles */
  border-radius: 8px;                 /* Slightly rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow: hidden;
}

#WACContainer.WACContainer .Carousel__TileTitle {
  color: #4d5358;                     /* Darker color for the title */
  font-weight: 700;                   /* Bold title */
  font-size: 12px;
  line-height: 1.2;
  margin: 0 0 10px;                    /* Space below title */
  text-align: left;
}

#WACContainer.WACContainer .Carousel__TileSubtitle {
  color: #8a8f94;                     /* Lighter color for subtitle */
  font-weight: 400;                   /* Normal weight for subtitle */
  font-size: 10px;
  line-height: 1.2;
  margin: 0;
  text-align: left;
}

//Single Card
#WACContainer.WACContainer .SingleCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  //background-color: #f4f4f4;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
}

#WACContainer.WACContainer .SingleCard__Container {
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

#WACContainer.WACContainer .SingleCard__Text {
  margin-bottom: 1rem;
}

#WACContainer.WACContainer .SingleCard__Title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #000000; /* Black title */
}

#WACContainer.WACContainer .SingleCard__Subtitle {
  font-size: 0.75rem;
  color: #666666; /* Gray subtitle */
  margin-bottom: 0.5rem;
}

#WACContainer.WACContainer .SingleCard__Description {
  font-size: 0.875rem;
  color: #333333; /* Darker gray description */
  margin-bottom: 1rem;
}

#WACContainer.WACContainer .SingleCard__Button {
  width: 100%;
  padding: 0.75rem;
  background-color: #e30613; /* Red button */
  color: #ffffff; /* White text */
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#WACContainer.WACContainer .SingleCard__Button:hover {
  background-color: #b0050f; /* Darker red on hover */
}

#WACContainer.WACContainer .SingleCard__Button:active {
  background-color: #8a040c; /* Even darker red on click */
}










